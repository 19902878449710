function GetURLParameter(sParam)
{
  var sPageURL = window.location.search.slice(1);
  var sURLVariables = sPageURL.split('&');
  for (var i = 0; i < sURLVariables.length; i++) 
  {
      var sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] === sParam) 
      {
          return sParameterName[1];
      }
  }
}

function App() {
  return (
    <stripe-pricing-table
      pricing-table-id="prctbl_1MmYMfLmNHYcpXdbvQR1FJ4w"
      publishable-key="pk_live_51McDloLmNHYcpXdbrBXLYxT4nMcUH2dgiycNNmdDvWJbljtAqs6Nwi2u533oB2pgFpWnpBXuRQZ1y47MAm7CyK4000EA6pwTJ2"
      customer-email={ GetURLParameter('email') }
    > 
    </stripe-pricing-table>
  );
}

export default App;